import dayjs, { Dayjs } from '.'

/**
 * 设置date1的日期为date2的日期，保持date1的时间不变
 *
 * @param date1
 * @param date2
 * @returns 改变的日期
 */
export const setToDate = (date1: Dayjs, date2: Dayjs): Dayjs => {
  const updatedDate = date2
    .hour(date1.hour())
    .minute(date1.minute())
    .second(date1.second())
    .millisecond(date1.millisecond())
  return updatedDate
}

/**
 * 设置date1的日期为date2的日期，保持date1的时间不变
 *
 * @param date1
 * @param date2
 * @returns 改变的日期
 */
export const setToToday = (date1: Dayjs): Dayjs => {
  return setToDate(date1, dayjs())
}

/**
 * 设置date1的时间为date2的时间，保持date1的日期不变
 *
 * @param date1
 * @param date2
 * @returns 改变的日期
 */
export const setToTime = (date1: Dayjs, date2: Dayjs): Dayjs => {
  const updatedDate = date2
    .year(date1.year())
    .month(date1.month())
    .day(date1.day())
  return updatedDate
}

/**
 * 返回最接近的分钟，例如，如果step是15，那么返回的分钟将是15的倍数。
 *
 * ### Example
 * ```typescript
 * const value = dayjs('2021-01-01 12:34:56');
 * const step = 15;
 * const closestMinute = findClosestMinute(value, step);
 * console.log(closestMinute);  => 12:30
 * ```
 *
 * @param value
 * @param step
 * @returns
 */
export const findClosestMinute = (value: Dayjs, step: number): number => {
  const isEndOfDay = value?.isSame(dayjs(value).endOf('day'), 'minute')
  if (isEndOfDay) {
    return dayjs(value).minute()
  }
  const minutes =
    Math.floor(value?.diff(dayjs(value).startOf('day'), 'minute') / step) * step
  return minutes
}

/**
 * 返回最接近的时间，例如，如果step是15，那么返回的分钟将是15的倍数。
 *
 * ### Example
 * ```typescript
 * const value = dayjs('2021-01-01 12:34:56');
 * const step = 15;
 * const closestTime = findClosestTimeWithStep(value, step);
 * console.log(closestMinute.format('YYYY-MM-DD HH:mm:ss')); // 2021-01-01 12:30:00
 * ```
 *
 * @param value
 * @param step
 * @returns
 */
export const findClosestTimeWithStep = (value: Dayjs, step: number): Dayjs => {
  const minutes = findClosestMinute(value, step)
  return dayjs(value).startOf('day').add(minutes, 'minute')
}
