import { Dayjs } from '.'
/**
 * Check if two time intervals intersect
 * @param {Array<Dayjs>} interval1 - Array with start and end times for the first interval [start, end]
 * @param {Array<Dayjs>} interval2 - Array with start and end times for the second interval [start, end]
 * @return {boolean} - Returns true if the intervals intersect, otherwise false
 */
export function areIntervalsIntersected(interval1: [Dayjs, Dayjs], interval2: [Dayjs, Dayjs]): boolean {
  const [start1, end1] = interval1;
  const [start2, end2] = interval2;

  // Check if the intervals intersect
  return start1.isBefore(end2) && start2.isBefore(end1);
}
