import originalDayjs from 'dayjs'

interface TimezoneStorage {
  get: () => string | undefined
  set: (timezone: string) => void
  run: <T>(timezone: string, callback: () => T) => T
}

let timezoneStorage: TimezoneStorage

if (typeof window === 'undefined') {
  // Node.js environment
  const { AsyncLocalStorage } = require('async_hooks')
  const asyncLocalStorage = new AsyncLocalStorage()

  timezoneStorage = {
    get: () => asyncLocalStorage.getStore(),
    set: (timezone: string) => asyncLocalStorage.enterWith(timezone),
    run: (timezone: string, callback: () => any) =>
      asyncLocalStorage.run(timezone, callback),
  }
} else {
  // Browser environment
  let currentTimezone: string | undefined

  timezoneStorage = {
    get: () => currentTimezone,
    set: (timezone: string) => {
      currentTimezone = timezone
    },
    run: (timezone: string, callback: () => any) => {
      const previousTimezone = currentTimezone
      currentTimezone = timezone
      try {
        return callback()
      } finally {
        currentTimezone = previousTimezone
      }
    },
  }
}

export { timezoneStorage }
