import type { Dayjs } from '.'
import dayjs from '.'
import { WeekdayFormat } from './weekday'

export interface FormatOptions {
  timeFormat?: 12 | 24,
  timeZone?: string,
  locale?: string,
  weekdayFormat?: WeekdayFormat
}

const DefaultOptions: FormatOptions = {
}

const getLocalDayjs = (date: Date | Dayjs | string, options?: FormatOptions) => {
  const { timeZone, locale } = options ?? DefaultOptions
  let localDayjs = timeZone
    ? dayjs(date)
      .tz(timeZone) : dayjs(date)
  localDayjs = locale ? localDayjs.locale(locale) : localDayjs
  return localDayjs
}

/**
 * Format only date: 06/03/2024, 2024/06/03 or 03/06/2024
 * 
 * @param date 
 * @returns 
 */
export const formatDate = (date: Date | Dayjs | string, options?: FormatOptions) => {
  if (!date) {
    return ''
  }
  return getLocalDayjs(date, options).format('L')
}

/**
 * Format ISO8601
 * 
 * @param date 
 * @returns 
 */
export const formatDateISO8601 = (date: Date | Dayjs | string, options?: FormatOptions) => {
  if (!date) {
    return ''
  }
  return getLocalDayjs(date, options).format('YYYY-MM-DD')
}

/**
 * Format only date: Jun 03, Mar 21
 * 
 * @param date 
 * @returns 
 */
export const formatDateMonthDay = (date: Date | Dayjs | string, options?: FormatOptions) => {
  if (!date) {
    return ''
  }
  return getLocalDayjs(date, options).format('MMM DD')
}

/**
 * Format only time: 08:00, 12:00, 23:59 or 08:00 PM
 * 
 * Expects timeFormat to be either 12 or 24, if null or undefined
 * is passed in, we always default back to 24 hour notation.
 */
export const formatTime = (
  date: string | Date | Dayjs,
  options?: FormatOptions
) => {
  if (!date) {
    return ''
  }
  // console.log(timeZone, date);
  return getLocalDayjs(date, options).format(options?.timeFormat === 12 ? 'hh:mmA' : 'HH:mm')
}

/**
 * Format DateTime: 06/03/2024 08:00, 2024/06/03 12:00, 03/06/2024 23:59 or 06/03/2024 08:00 PM
 * 
 * @param date 
 * @returns 
 */
export const formatDateTime = (date: Date | Dayjs | string,
  options?: FormatOptions) => {
  if (!date) {
    return ''
  }
  return getLocalDayjs(date, options).format(options?.timeFormat === 12 ? 'L hh:mmA' : 'L HH:mm')
}


/**
 * Format date range: 06/03/2024 - 06/03/2024, 2024/06/03 - 2024/06/03 or 03/06/2024 - 03/06/2024
 * 
 * @param param0 [fromDate, toDate] 
 * @param timeZone
 * @returns 
 */
export const formatDateRange = ([fromDate, toDate]: [fromDate: Date | Dayjs | string, toDate: Date | Dayjs | string], options?: FormatOptions) => {
  return `${formatDate(fromDate, options)} - ${formatDate(toDate, options)}`
}

/**
 * Format Time range: 12:00 - 13:00
 * 
 * @param param0 [fromDate, toDate] 
 * @param timeFormat 
 * @param timeZone
 * @returns 
 */
export const formatTimeRange = ([fromDate, toDate]: [fromDate: Date | Dayjs | string, toDate: Date | Dayjs | string], options?: FormatOptions) => {
  return `${formatTime(fromDate, options)} - ${formatTime(toDate, options)}`
}

/**
 * Format DateTime range: 2024/06/03 12:00 - 13:00 or 03/06/2024 23:59 - 03/07/2024 23:59
 * 
 * @param param0 [fromDate, toDate] 
 * @param timeZone 
 * @returns 
 */
export const formatDateTimeRange = ([fromDate, toDate]: [fromDate: Date | Dayjs | string, toDate: Date | Dayjs | string], options?: FormatOptions) => {
  const fromDay = options?.timeZone
    ? dayjs(fromDate)
      .tz(options?.timeZone) : dayjs(fromDate)
  const toDay = options?.timeZone
    ? dayjs(toDate)
      .tz(options?.timeZone) : dayjs(toDate)

  if (fromDay.isSame(toDay, 'day')) {
    return `${formatDateTime(fromDate, options)} - ${formatTime(toDate, options)}`
  } else {
    return `${formatDateTime(fromDate, options)} - ${formatDateTime(toDate, options)}`
  }
}