import { Dayjs } from "dayjs";

/**
 * 时间段，[开始时间，结束时间]
 */
export type TimeRange = [Dayjs, Dayjs]

/**
 * 时段（场次）
 */
export interface ITimeSegment {
  /**
   * 'AM', 'PM', or name of time slots
   */
  name: string; 
  /**
   * Use string to represent time, e.g., "09:00:00"
   */
  start: string; 
  /**
   * Use string to represent time, e.g., "17:00:00"
   */
  end: string;
}

/**
 * 每天的时间段
 */
export interface ITimeModeDay {
  /**
   * 是否全天不开放。如果是true，segments应为空或null
   */
  all_day_closed: boolean; 
  /**
   * 时段数组。只有all_day_closed为false的时候才有效。
   */
  segments?: ITimeSegment[];
}

/**
 * 每星期的时间安排
 */
export interface ITimeModeWeekdays {
  mon: ITimeModeDay;
  tue: ITimeModeDay;
  wed: ITimeModeDay;
  thu: ITimeModeDay;
  fri: ITimeModeDay;
  sat: ITimeModeDay;
  sun: ITimeModeDay;
}

/**
 * 特殊日期的时间安排
 */
export interface ITimeModeDateOverride {
  /**
   * 名称，例如'国庆节'
   */
  name: string; 
  /**
   * 日期，格式为 "YYYY-MM-DD"
   */
  date: string; 
  /**
   * 该天的日期安排
   */
  mode: ITimeModeDay;
}

/**
 * 代表时间模式
 */
export interface ITimeModeEntity {
  /**
   * 通常周几的时间安排
   */
  weekdays: ITimeModeWeekdays;
  /**
   * 特殊日期的时间安排
   */
  date_overrides: ITimeModeDateOverride[];
}

export const DAYS_OF_WEEK = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

/**
 * 默认早九晚五的周几安排。
 */
export const DEFAULT_WORKING_HOURS: ITimeModeWeekdays = DAYS_OF_WEEK.reduce((acc, day: string) => {
  if (day === 'sat' || day === 'sun') {
    acc[day] = {
      all_day_closed: true,
    } as ITimeModeDay;;
  } else {
    acc[day as keyof ITimeModeWeekdays] = {
      all_day_closed: false,
      segments: [{ name: 'AM', start: '09:00:00', end: '12:00:00' }, { name: 'PM', start: '12:00:00', end: '17:00:00' }]
    } as ITimeModeDay;
  }
  return acc;
}, {} as ITimeModeWeekdays);

export enum DaysNames {
  sun = 0,
  mon = 1,
  tue = 2,
  wed = 3,
  thu = 4,
  fri = 5,
  sat = 6,
}

export type BusinessUnitType =
| 'minute'
| 'minutes'
| 'hour'
| 'hours'
| 'day'
| 'days'
| 'second'
| 'seconds';