
export interface Timezone {
  value: string
  abbreviation: string
  altName: string
  offset: string
}
/**
 * List of essential timezones, such as GMT, UTC, and other major cities.
 */
export const ESSENTIAL_TIME_ZONES: Timezone[] = [
  {
    value: 'Etc/GMT+12',
    altName: 'International Date Line West',
    abbreviation: 'DST',
    offset: '-12:00'
  },
  {
    value: 'Etc/GMT+11',
    altName: 'Coordinated Universal Time-11',
    abbreviation: 'UTC-11',
    offset: '-11:00'
  },
  { value: 'Pacific/Honolulu', altName: 'Hawaii', abbreviation: 'HST', offset: '-10:00' },
  { value: 'America/Anchorage', altName: 'Alaska', abbreviation: 'AKST', offset: '-09:00' },
  {
    value: 'America/Tijuana',
    altName: 'Baja California',
    abbreviation: 'PST',
    offset: '-08:00'
  },
  {
    value: 'America/Los_Angeles',
    altName: 'Pacific Time (US & Canada)',
    abbreviation: 'PST',
    offset: '-08:00'
  },
  { value: 'America/Phoenix', altName: 'Arizona', abbreviation: 'MST', offset: '-07:00' },
  {
    value: 'America/Chihuahua',
    altName: 'Chihuahua, La Paz, Mazatlan',
    abbreviation: 'MST',
    offset: '-07:00'
  },
  {
    value: 'America/Denver',
    altName: 'Mountain Time (US & Canada)',
    abbreviation: 'MST',
    offset: '-07:00'
  },
  {
    value: 'America/Guatemala',
    altName: 'Central America',
    abbreviation: 'CST',
    offset: '-06:00'
  },
  {
    value: 'America/Chicago',
    altName: 'Central Time (US & Canada)',
    abbreviation: 'CST',
    offset: '-06:00'
  },
  {
    value: 'America/Mexico_City',
    altName: 'Guadalajara, Mexico City, Monterrey',
    abbreviation: 'CST',
    offset: '-06:00'
  },
  { value: 'America/Regina', altName: 'Saskatchewan', abbreviation: 'CST', offset: '-06:00' },
  {
    value: 'America/Bogota',
    altName: 'Bogota, Lima, Quito',
    abbreviation: 'COT',
    offset: '-05:00'
  },
  {
    value: 'America/New_York',
    altName: 'Eastern Time (US & Canada)',
    abbreviation: 'EST',
    offset: '-05:00'
  },
  {
    value: 'America/Indiana/Indianapolis',
    altName: 'Indiana (East)',
    abbreviation: 'EST',
    offset: '-05:00'
  },
  { value: 'America/Caracas', altName: 'Caracas', abbreviation: 'VET', offset: '-04:30' },
  { value: 'America/Asuncion', altName: 'Asuncion', abbreviation: 'PYST', offset: '-04:00' },
  {
    value: 'America/Halifax',
    altName: 'Atlantic Time (Canada)',
    abbreviation: 'AST',
    offset: '-04:00'
  },
  { value: 'America/Cuiaba', altName: 'Cuiaba', abbreviation: 'CBT', offset: '-04:00' },
  {
    value: 'America/La_Paz',
    altName: 'Georgetown, La Paz, Manaus, San Juan',
    abbreviation: 'SAWST',
    offset: '-04:00'
  },
  { value: 'America/Santiago', altName: 'Santiago', abbreviation: 'PSAST', offset: '-04:00' },
  { value: 'America/St_Johns', altName: 'Newfoundland', abbreviation: 'NST', offset: '-03:30' },
  { value: 'America/Sao_Paulo', altName: 'Brasilia', abbreviation: 'ESAST', offset: '-03:00' },
  {
    value: 'America/Argentina/Buenos_Aires',
    altName: 'Buenos Aires',
    abbreviation: 'ART',
    offset: '-03:00'
  },
  {
    value: 'America/Cayenne',
    altName: 'Cayenne, Fortaleza',
    abbreviation: 'SAEST',
    offset: '-03:00'
  },
  { value: 'America/Godthab', altName: 'Greenland', abbreviation: 'GST', offset: '-03:00' },
  { value: 'America/Montevideo', altName: 'Montevideo', abbreviation: 'MST', offset: '-03:00' },
  { value: 'America/Bahia', altName: 'Salvador', abbreviation: 'BST', offset: '-03:00' },
  {
    value: 'Etc/GMT+2',
    altName: 'Coordinated Universal Time-02',
    abbreviation: 'UTC-02',
    offset: '-02:00'
  },
  { value: 'Etc/GMT+2', altName: 'Mid-Atlantic - Old', abbreviation: 'MAST', offset: '-02:00' },
  { value: 'Atlantic/Azores', altName: 'Azores', abbreviation: 'AST', offset: '-01:00' },
  {
    value: 'Atlantic/Cape_Verde',
    altName: 'Cape Verde Is.',
    abbreviation: 'CVST',
    offset: '-01:00'
  },
  { value: 'Africa/Casablanca', altName: 'Casablanca', abbreviation: 'MST', offset: '+00:00' },
  {
    value: 'Etc/UTC',
    altName: 'Coordinated Universal Time',
    abbreviation: 'UTC',
    offset: '+00:00'
  },
  {
    value: 'Europe/London',
    altName: 'Dublin, Edinburgh, Lisbon, London',
    abbreviation: 'GMT',
    offset: '+00:00'
  },
  {
    value: 'Atlantic/Reykjavik',
    altName: 'Monrovia, Reykjavik',
    abbreviation: 'GST',
    offset: '+00:00'
  },
  {
    value: 'Europe/Berlin',
    altName: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    abbreviation: 'WEST',
    offset: '+01:00'
  },
  {
    value: 'Europe/Budapest',
    altName: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    abbreviation: 'CEST',
    offset: '+01:00'
  },
  {
    value: 'Europe/Paris',
    altName: 'Brussels, Copenhagen, Madrid, Paris',
    abbreviation: 'RST',
    offset: '+01:00'
  },
  {
    value: 'Europe/Warsaw',
    altName: 'Sarajevo, Skopje, Warsaw, Zagreb',
    abbreviation: 'CEST',
    offset: '+01:00'
  },
  {
    value: 'Africa/Lagos',
    altName: 'West Central Africa',
    abbreviation: 'WCAST',
    offset: '+01:00'
  },
  { value: 'Africa/Windhoek', altName: 'Windhoek', abbreviation: 'NST', offset: '+01:00' },
  {
    value: 'Europe/Bucharest',
    altName: 'Athens, Bucharest',
    abbreviation: 'GTBST',
    offset: '+02:00'
  },
  { value: 'Asia/Beirut', altName: 'Beirut', abbreviation: 'MEST', offset: '+02:00' },
  { value: 'Africa/Cairo', altName: 'Cairo', abbreviation: 'EST', offset: '+02:00' },
  { value: 'Asia/Damascus', altName: 'Damascus', abbreviation: 'SST', offset: '+02:00' },
  { value: 'Europe/Kiev', altName: 'E. Europe', abbreviation: 'EEST', offset: '+02:00' },
  {
    value: 'Africa/Johannesburg',
    altName: 'Harare, Pretoria',
    abbreviation: 'SAST',
    offset: '+02:00'
  },
  {
    value: 'Europe/Kiev',
    altName: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    abbreviation: 'FLEST',
    offset: '+02:00'
  },
  { value: 'Europe/Istanbul', altName: 'Istanbul', abbreviation: 'TST', offset: '+03:00' },
  { value: 'Asia/Jerusalem', altName: 'Jerusalem', abbreviation: 'IST', offset: '+02:00' },
  { value: 'Africa/Tripoli', altName: 'Tripoli', abbreviation: 'LST', offset: '+02:00' },
  { value: 'Asia/Amman', altName: 'Amman', abbreviation: 'JST', offset: '+03:00' },
  { value: 'Asia/Baghdad', altName: 'Baghdad', abbreviation: 'AST', offset: '+03:00' },
  { value: 'Europe/Kaliningrad', altName: 'Kaliningrad', abbreviation: 'KST', offset: '+02:00' },
  { value: 'Asia/Riyadh', altName: 'Kuwait, Riyadh', abbreviation: 'AST', offset: '+03:00' },
  { value: 'Africa/Nairobi', altName: 'Nairobi', abbreviation: 'EAST', offset: '+03:00' },
  { value: 'Asia/Tehran', altName: 'Tehran', abbreviation: 'IRST', offset: '+03:30' },
  { value: 'Asia/Dubai', altName: 'Abu Dhabi, Muscat', abbreviation: 'AST', offset: '+04:00' },
  { value: 'Asia/Baku', altName: 'Baku', abbreviation: 'AZST', offset: '+04:00' },
  { value: 'Indian/Mauritius', altName: 'Port Louis', abbreviation: 'MST', offset: '+04:00' },
  { value: 'Asia/Tbilisi', altName: 'Tbilisi', abbreviation: 'GET', offset: '+04:00' },
  { value: 'Asia/Yerevan', altName: 'Yerevan', abbreviation: 'CST', offset: '+04:00' },
  { value: 'Asia/Kabul', altName: 'Kabul', abbreviation: 'AFST', offset: '+04:30' },
  {
    value: 'Asia/Tashkent',
    altName: 'Ashgabat, Tashkent',
    abbreviation: 'WAST',
    offset: '+05:00'
  },
  {
    value: 'Asia/Karachi',
    altName: 'Islamabad, Karachi',
    abbreviation: 'PKST',
    offset: '+05:00'
  },
  {
    value: 'Asia/Kolkata',
    altName: 'Chennai, Kolkata, Mumbai, New Delhi',
    abbreviation: 'IST',
    offset: '+05:30'
  },
  {
    value: 'Asia/Colombo',
    altName: 'Sri Jayawardenepura',
    abbreviation: 'SLST',
    offset: '+05:30'
  },
  { value: 'Asia/Kathmandu', altName: 'Kathmandu', abbreviation: 'NST', offset: '+05:45' },
  { value: 'Asia/Almaty', altName: 'Astana', abbreviation: 'CAST', offset: '+06:00' },
  { value: 'Asia/Dhaka', altName: 'Dhaka', abbreviation: 'BST', offset: '+06:00' },
  { value: 'Asia/Yangon', altName: 'Yangon (Rangoon)', abbreviation: 'MST', offset: '+06:30' },
  {
    value: 'Asia/Bangkok',
    altName: 'Bangkok, Hanoi, Jakarta',
    abbreviation: 'SEAST',
    offset: '+07:00'
  },
  { value: 'Asia/Novosibirsk', altName: 'Novosibirsk', abbreviation: 'NCAST', offset: '+07:00' },
  {
    value: 'Asia/Shanghai',
    altName: 'Beijing, Chongqing, Hong Kong, Urumqi',
    abbreviation: 'CST',
    offset: '+08:00'
  },
  { value: 'Asia/Krasnoyarsk', altName: 'Krasnoyarsk', abbreviation: 'NAST', offset: '+08:00' },
  {
    value: 'Asia/Singapore',
    altName: 'Kuala Lumpur, Singapore',
    abbreviation: 'SST',
    offset: '+08:00'
  },
  { value: 'Australia/Perth', altName: 'Perth', abbreviation: 'WAST', offset: '+08:00' },
  { value: 'Asia/Taipei', altName: 'Taipei', abbreviation: 'TST', offset: '+08:00' },
  { value: 'Asia/Ulaanbaatar', altName: 'Ulaanbaatar', abbreviation: 'ULAT', offset: '+08:00' },
  { value: 'Asia/Irkutsk', altName: 'Irkutsk', abbreviation: 'NAEST', offset: '+09:00' },
  {
    value: 'Asia/Tokyo',
    altName: 'Osaka, Sapporo, Tokyo',
    abbreviation: 'JST',
    offset: '+09:00'
  },
  { value: 'Asia/Seoul', altName: 'Seoul', abbreviation: 'KST', offset: '+09:00' },
  { value: 'Australia/Adelaide', altName: 'Adelaide', abbreviation: 'CAST', offset: '+09:30' },
  { value: 'Australia/Darwin', altName: 'Darwin', abbreviation: 'ACST', offset: '+09:30' },
  { value: 'Australia/Brisbane', altName: 'Brisbane', abbreviation: 'EAST', offset: '+10:00' },
  {
    value: 'Australia/Sydney',
    altName: 'Canberra, Melbourne, Sydney',
    abbreviation: 'AEST',
    offset: '+10:00'
  },
  {
    value: 'Pacific/Port_Moresby',
    altName: 'Guam, Port Moresby',
    abbreviation: 'WPST',
    offset: '+10:00'
  },
  { value: 'Australia/Hobart', altName: 'Hobart', abbreviation: 'TST', offset: '+10:00' },
  { value: 'Asia/Yakutsk', altName: 'Yakutsk', abbreviation: 'YST', offset: '+10:00' },
  {
    value: 'Pacific/Guadalcanal',
    altName: 'Solomon Is., New Caledonia',
    abbreviation: 'CPST',
    offset: '+11:00'
  },
  { value: 'Asia/Vladivostok', altName: 'Vladivostok', abbreviation: 'VST', offset: '+11:00' },
  {
    value: 'Pacific/Auckland',
    altName: 'Auckland, Wellington',
    abbreviation: 'NZST',
    offset: '+12:00'
  },
  {
    value: 'Etc/GMT-12',
    altName: 'Coordinated Universal Time+12',
    abbreviation: 'UTC+12',
    offset: '+12:00'
  },
  { value: 'Pacific/Fiji', altName: 'Fiji', abbreviation: 'FST', offset: '+12:00' },
  { value: 'Asia/Magadan', altName: 'Magadan', abbreviation: 'MST', offset: '+12:00' },
  {
    value: 'Asia/Kamchatka',
    altName: 'Petropavlovsk-Kamchatsky - Old',
    abbreviation: 'KST',
    offset: '+12:00'
  },
  { value: 'Pacific/Tongatapu', altName: "Nuku'alofa", abbreviation: 'TST', offset: '+13:00' },
  { value: 'Pacific/Apia', altName: 'Samoa', abbreviation: 'SST', offset: '+13:00' }
]
